<div class="config-section-header">
  <div class="config-links-wrapper" *ngIf="showSettingsContent">
    <div
      class="link"
      (click)="onClickSectionLink('settings')"
      [class.active]="
        configSection === 'settings' && jobData.jobType === 'simulator'
      "
    >
      Config
    </div>
    <div
      *ngIf="jobData.jobType === 'simulator'"
      class="link"
      (click)="onClickSectionLink('lineups')"
      [class.active]="configSection === 'lineups'"
    >
      Add Lineups
      <div
        class="star"
        *ngIf="jobData.optimizerSeed && jobData.lineups !== null"
      >
        <svg-icon
          [svgStyle]="{ 'height.px': 18 }"
          src="/assets/icons/power-chip.svg"
        ></svg-icon>
      </div>
    </div>

    <div
      *ngIf="
        jobData.jobType === 'simulator' &&
        jobData.site === 'dk' &&
        (jobData.sport === 'NFL' || jobData.sport === 'NBA') &&
        isContestStarted
      "
      class="link"
      (click)="onClickSectionLink('lateSwap')"
      [class.active]="configSection === 'lateSwap'"
    >
      Late Swap
    </div>
  </div>
  <div class="icon-utility-btn" (click)="onClickMinimizeConfig()">
    <svg-icon
      *ngIf="showSettingsContent"
      [svgStyle]="{ 'height.px': 18 }"
      src="/assets/icons/arrow-right-square.svg"
    ></svg-icon>
    <svg-icon
      *ngIf="!showSettingsContent"
      [svgStyle]="{ 'height.px': 18 }"
      src="/assets/icons/arrow-left-square.svg"
    ></svg-icon>
  </div>
</div>

<div
  class="config-sections"
  *ngIf="
    jobData?.options &&
    jobData.draftGroup &&
    showSettingsContent &&
    configSection === 'settings'
  "
>
  <!-- MLB -->
  <mlb-sim-options
    *ngIf="jobData.sport === 'MLB' && jobData.jobType === 'simulator'"
    [jobData]="jobData"
  ></mlb-sim-options>
  <mlb-opto-options
    *ngIf="jobData.sport === 'MLB' && jobData.jobType === 'optimizer'"
    [jobData]="jobData"
  ></mlb-opto-options>

  <!-- PGA -->
  <pga-sim-options
    *ngIf="jobData.sport === 'PGA' && jobData.jobType === 'simulator'"
    [jobData]="jobData"
  ></pga-sim-options>
  <pga-opto-options
    *ngIf="jobData.sport === 'PGA' && jobData.jobType === 'optimizer'"
    [jobData]="jobData"
  ></pga-opto-options>

  <!-- MMA -->
  <mma-sim-options
    *ngIf="jobData.sport === 'MMA' && jobData.jobType === 'simulator'"
    [jobData]="jobData"
  ></mma-sim-options>
  <mma-opto-options
    *ngIf="jobData.sport === 'MMA' && jobData.jobType === 'optimizer'"
    [jobData]="jobData"
  ></mma-opto-options>

  <!-- TEN -->
  <ten-sim-options
    *ngIf="jobData.sport === 'TEN' && jobData.jobType === 'simulator'"
    [jobData]="jobData"
  ></ten-sim-options>
  <ten-opto-options
    *ngIf="jobData.sport === 'TEN' && jobData.jobType === 'optimizer'"
    [jobData]="jobData"
  ></ten-opto-options>

  <!-- NFL -->
  <nfl-sim-options
    *ngIf="jobData.sport === 'NFL' && jobData.jobType === 'simulator'"
    [jobData]="jobData"
  ></nfl-sim-options>
  <nfl-opto-options
    *ngIf="jobData.sport === 'NFL' && jobData.jobType === 'optimizer'"
    [jobData]="jobData"
  ></nfl-opto-options>

  <!-- NBA -->
  <nba-sim-options
    *ngIf="jobData.sport === 'NBA' && jobData.jobType === 'simulator'"
    [jobData]="jobData"
  ></nba-sim-options>
  <nba-opto-options
    *ngIf="jobData.sport === 'NBA' && jobData.jobType === 'optimizer'"
    [jobData]="jobData"
  ></nba-opto-options>
</div>

<div
  class="config-sections"
  *ngIf="
    jobData?.options &&
    jobData.jobSubType &&
    showSettingsContent &&
    configSection === 'lineups'
  "
>
  <universal-lineups-import
    [jobData]="jobData"
    [jobDraftGroup]="jobData.draftGroup"
  ></universal-lineups-import>
</div>

<div class="config-sections" *ngIf="configSection === 'lateSwap'">
  <universal-late-swap [jobData]="jobData"></universal-late-swap>
</div>
