<div class="draft-group-projections-wrapper">
  <div class="projections-data-header">
    <div class="left">
      <div class="player-title-row">
        <div class="players-title">Selected Players</div>
        <div class="total-count" *ngIf="totalSelections && totalSelections > 0">
          {{ totalSelections | number }}
        </div>
      </div>

      <div class="search-and-positions">
        <div class="table-search-wrapper">
          <input
            id="searchValue"
            name="searchValue"
            class="table-search"
            [(ngModel)]="searchValue"
            (ngModelChange)="handleSearchPlayer($event)"
            placeholder="Player Name..."
            autocomplete="off"
          />
        </div>

        <!-- <div class="positions-sort-row">
          <div class="position">P</div>
          <div class="position">P</div>
        </div> -->

        <div class="positions-sort-row">
          <div
            class="position non-selectable cursor-p"
            *ngFor="let p of positionFilters"
            [ngClass]="{ on: p === jobData?.currentSort }"
            (click)="onClickPosition(p)"
          >
            {{ p }}
          </div>
        </div>
      </div>
    </div>

    <div class="tools-wrapper">
      <div class="tool-btn" (click)="toggleShowImportModal($event)">
        <div class="icon">
          <svg-icon
            [svgStyle]="{ 'height.px': 20 }"
            src="/assets/icons/import-square.svg"
          ></svg-icon>
        </div>
        <div class="text">Import Projections</div>
      </div>
    </div>
  </div>

  <div
    class="teams-row"
    *ngIf="
      jobData?.currentMatches &&
      jobData.sport !== 'PGA' &&
      jobData.sport !== 'MMA' &&
      jobData.sport !== 'TEN' &&
      jobData?.currentSelectedMatch == null
    "
  >
    <team-filter-widget
      *ngFor="let m of jobData.currentMatches"
      [jobData]="jobData"
      [match]="m"
    ></team-filter-widget>
  </div>

  <div class="team-bar-row" *ngIf="jobData?.currentSelectedMatch">
    <team-filter-bar
      [jobData]="jobData"
      [match]="jobData?.currentSelectedMatch"
      index="0"
    ></team-filter-bar>
  </div>

  <div
    class="draft-group-table-wrapper"
    *ngIf="
      jobData?.draftGroup &&
      jobData?.draftGroup.length > 0 &&
      !isLoadingTableConfig
    "
  >
    <base-table
      #draftGroupTableComponent
      [jobData]="jobData"
      [rows]="jobData.draftGroup"
      [columns]="columns"
      (handleSelectAllRows)="selectAllRows($event)"
      (handleSelectSingleRow)="toggleRowSelection($event)"
      [allRowsSelected]="allRowsSelected"
      (hanleEditComplete)="onEditComplete()"
      selectable="true"
      searchKey="Name"
      hideToolsRow="true"
      [showPlayerBoost]="jobData.sport === 'NFL' ? true : false"
      [maxHeightOverride]="getMaxHeight()"
    ></base-table>
  </div>
</div>

<!-- IMPORT MODAL -->
<div class="draft-group-import-data-wrapper" *ngIf="showImportModal">
  <div class="import-modal" #modalWrapper>
    <div class="import-header">
      <div class="header-text">Import Player Data</div>
      <div class="close-icon" (click)="toggleShowImportModal($event)">
        <svg-icon
          class="icon"
          [svgStyle]="{ 'height.px': 18 }"
          src="/assets/icons/close-square.svg"
        ></svg-icon>
      </div>
    </div>
    <div class="import-body">
      <draft-group-data
        *ngIf="jobData.draftGroup"
        [jobData]="jobData"
        (importComplete)="handleCloseImportModal($event)"
      ></draft-group-data>
    </div>
  </div>
</div>
