import { PGA_TABLES_SIM, PGA_TABLES_OPTO } from "../job-constants/PGA/pga-table-configs";
import { MLB_TABLES_SIM, MLB_TABLES_OPTO } from "../job-constants/MLB/mlb-table-configs";
import { TEN_TABLES_SIM, TEN_TABLES_OPTO } from "../job-constants/TEN/ten-table-configs";
import { MMA_TABLES_SIM, MMA_TABLES_OPTO } from "../job-constants/MMA/mma-table-configs";
import { NFL_TABLES_SIM, NFL_TABLES_OPTO } from "../job-constants/NFL/nfl-table-configs";
import { NBA_TABLES_SIM, NBA_TABLES_OPTO } from "../job-constants/NBA/nba-table-configs";

const configMap = {
  'MLB_simulator_classic_dk': MLB_TABLES_SIM.projections_classic,
  'MLB_simulator_classic_fd': MLB_TABLES_SIM.projections_classic,
  'MLB_simulator_showdown_dk': MLB_TABLES_SIM.projections_showdown_dk,
  'MLB_simulator_showdown_fd': MLB_TABLES_SIM.projections_showdown_fd,
  'MLB_optimizer_classic_dk': MLB_TABLES_OPTO.projections_classic,
  'MLB_optimizer_classic_fd': MLB_TABLES_OPTO.projections_classic,
  'MLB_optimizer_showdown_dk': MLB_TABLES_OPTO.projections_showdown_dk,
  'MLB_optimizer_showdown_fd': MLB_TABLES_OPTO.projections_showdown_fd,

  'MMA_simulator_classic_dk': MMA_TABLES_SIM.projections_classic,
  'MMA_simulator_classic_fd': MMA_TABLES_SIM.projections_classic,
  'MMA_optimizer_classic_dk': MMA_TABLES_OPTO.projections_classic,
  'MMA_optimizer_classic_fd': MMA_TABLES_OPTO.projections_classic,
  'MMA_optimizer_showdown_dk': MMA_TABLES_OPTO.projections_showdown_dk,
  'MMA_optimizer_showdown_fd': MMA_TABLES_OPTO.projections_showdown_fd,

  'TEN_simulator_classic_dk': TEN_TABLES_SIM.projections_classic,
  'TEN_simulator_classic_fd': TEN_TABLES_SIM.projections_classic,
  'TEN_optimizer_classic_dk': TEN_TABLES_OPTO.projections_classic,
  'TEN_optimizer_classic_fd': TEN_TABLES_OPTO.projections_classic,
  'TEN_optimizer_showdown_dk': TEN_TABLES_OPTO.projections_showdown_dk,
  'TEN_optimizer_showdown_fd': TEN_TABLES_OPTO.projections_showdown_fd,

  'PGA_simulator_classic_dk': PGA_TABLES_SIM.projections_classic,
  'PGA_simulator_classic_fd': PGA_TABLES_SIM.projections_classic,
  'PGA_simulator_showdown_dk': PGA_TABLES_SIM.projections_showdown_dk,
  'PGA_simulator_showdown_fd': PGA_TABLES_SIM.projections_showdown_fd,
  'PGA_simulator_classic_tournament_dk': PGA_TABLES_SIM.projections_classic_tournament,
  'PGA_simulator_classic_tournament_fd': PGA_TABLES_SIM.projections_classic_tournament,
  'PGA_optimizer_classic_dk': PGA_TABLES_OPTO.projections_classic,
  'PGA_optimizer_classic_fd': PGA_TABLES_OPTO.projections_classic,
  'PGA_optimizer_showdown_dk': PGA_TABLES_OPTO.projections_showdown_dk,
  'PGA_optimizer_showdown_fd': PGA_TABLES_OPTO.projections_showdown_fd,

  'NFL_simulator_classic_dk': NFL_TABLES_SIM.projections_classic,
  'NFL_simulator_classic_fd': NFL_TABLES_SIM.projections_classic,
  'NFL_optimizer_classic_dk': NFL_TABLES_OPTO.projections_classic,
  'NFL_optimizer_classic_fd': NFL_TABLES_OPTO.projections_classic,
  'NFL_optimizer_showdown_dk': NFL_TABLES_OPTO.projections_showdown_dk,
  'NFL_optimizer_showdown_fd': NFL_TABLES_OPTO.projections_showdown_fd,
  'NFL_simulator_showdown_dk': NFL_TABLES_SIM.projections_showdown_dk,
  'NFL_simulator_showdown_fd': NFL_TABLES_SIM.projections_showdown_fd,

  'NBA_simulator_classic_dk': NBA_TABLES_SIM.projections_classic,
  'NBA_simulator_classic_fd': NBA_TABLES_SIM.projections_classic,
  'NBA_optimizer_classic_dk': NBA_TABLES_OPTO.projections_classic,
  'NBA_optimizer_classic_fd': NBA_TABLES_OPTO.projections_classic,
  'NBA_optimizer_showdown_dk': NBA_TABLES_OPTO.projections_showdown_dk,
  'NBA_optimizer_showdown_fd': NBA_TABLES_OPTO.projections_showdown_fd,
  'NBA_simulator_showdown_dk': NBA_TABLES_SIM.projections_showdown_dk,
  'NBA_simulator_showdown_fd': NBA_TABLES_SIM.projections_showdown_fd,


};

async function setProjectionTableConfig(sport, site, type, subtype) {
  // Generate the key for configuration retrieval
  const key = `${sport}_${type}_${subtype}_${site}`;
  console.log('key', key);

  // Retrieve configuration based on the key
  const tableConfig = configMap[key];

  if (!tableConfig) {
    throw new Error('Configuration to set table not found for the given sport, site, and type');
  }

  return tableConfig;
}

export default setProjectionTableConfig;
