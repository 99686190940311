<div class="config-section-header">
    <div class="config-links-wrapper"
        *ngIf="showSettingsContent">
        <div class="link"
            (click)="onClickSectionLink('players')"
            [class.active]="configSection === 'players'">
            PLAYER EXPOSURE
        </div>
        <div *ngIf="getTeamPanelAvailable(jobData.sport)"
            class="link"
            (click)="onClickSectionLink('teams')"
            [class.active]="configSection === 'teams'">
            TEAM EXPOSURE
        </div>
    </div>
    <div class="icon-utility-btn"
        (click)="onClickMinimizeConfig()"
        [ngStyle]="{'margin-right' : showSettingsContent ? '4px' : '0px'}"
        >
        <svg-icon *ngIf="showSettingsContent"
            [svgStyle]="{ 'height.px':18 }"
            src="/assets/icons/arrow-right-square.svg"></svg-icon>
        <svg-icon *ngIf="!showSettingsContent"
            [svgStyle]="{ 'height.px':18 }"
            src="/assets/icons/arrow-left-square.svg"></svg-icon>
    </div>
</div>

<div class="config-sections"
[ngClass]="{'active': showSettingsContent && configSection === 'players'}">

    <side-panel-players *ngIf="jobData?.currentPlayers" [jobData]="jobData"></side-panel-players>

</div>

<div class="config-sections"
    [ngClass]="{'active': showSettingsContent && configSection === 'teams'}">
    
   <side-panel-teams [jobData]="jobData" *ngIf="getTeamPanelAvailable(jobData.sport)"></side-panel-teams>

</div>