import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'job-results-side-panel',
  templateUrl: './job-results-side-panel.component.html',
  styleUrls: ['./job-results-side-panel.component.scss']
})
export class JobResultsSidePanelComponent {
  @Input() jobData: any;
  @Output() handleHideConfig: EventEmitter<void> = new EventEmitter<void>();

  showSettingsContent: boolean = true;
  configSection: string = 'players'; // teams

  onClickMinimizeConfig() {
    this.showSettingsContent = !this.showSettingsContent;
    this.handleHideConfig.emit();
  }

  onClickSectionLink(section) {
    this.configSection = section;
  }

  getTeamPanelAvailable(sport) {

    switch(sport) {
      case 'PGA': 
        return false;
      case 'MMA':
        return false;
      case 'TEN':
        return false;
      default:
        return true;
    }
  }

}
